.selectedLocation__text {
    margin-left: 7px;
    font-size: 13px;
    font-style: italic;
}

.addLocation__button {
    margin-top: 15px;
    padding: 10px;
}

.menuItem {
    display: flex;
    justify-content: space-between;
}
