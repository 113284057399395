.button {
    background: none !important;
    color: #242424 !important;
    display: flex !important;
    gap: 20px !important;
    justify-content: left !important;
    margin: 0 !important;
    border-radius: 0 !important;
}
.button * {
    color: #242424
}

.button:hover {
    background: #dadada !important;
}

.icon * {
    color: #242424 !important;
}