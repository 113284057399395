.page__container {
    width: 95vw;
    display: block;
    margin: auto;
}

.filters__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px
}

.organisationFilter__container {
    width: 400px;
}
.startDateFilter__container {
    display: block;
}