.modal {
    min-width: 800px;  
}

@media (max-width: 850px) {
    .modal {
        min-width: 100px;
        width: 90%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.idText {
    font-weight: normal;
    text-align: center;
    font-size: 14px;
}

.text {
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    margin: 0;
}

