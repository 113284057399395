@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;700;800&display=swap');
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}
* {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #242424;
}
a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
}

:root {
  --color-text-default: #1B1B1F;
  --color-text-subtle: #5C5C66;
  --color-border-default: #CACAD1;
  --color-border-hover: #AFAFB8;
  --color-border-active: #84848F;
  --color-primary: #0063FB;
  --color-primary-hover: #244EB3;
  --color-primary-active: #6485fa;
  --color-background-default: #FFFFFF;
  --color-background-subtle: #F6F6F6;
  --color-background-hover: #F0F0F2;
  --color-positive: #1A8F64;
  --color-negative: #CF070E;
  --color-negative-hover: #96050A;
  --color-warning: #F38E30;
  --color-gray-50: #F6F6F6;
  --color-gray-100: #F0F0F2;
  --color-gray-600: #5C5C66;
}
