
.textColor {
    color: var(--color-text-default) !important;
}

.textField * {
    color: var(--color-text-default) !important;
}

.textField {
    /* These colors overridde, so the textfield dont get other colors, when you select an autocomplete option like a phonenumber */
    border-radius: 6px !important;
}

.textField fieldset {
    border-color: var(--color-border-default) !important;
}

.textField fieldset:hover {
    border-color: var(--color-border-hover) !important;
}

.textField fieldset:hover {
    border-color: var(--color-border-active) !important;
}