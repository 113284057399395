.container {
    margin-top: 20px;
}

.loadingSpinner__container {
    margin-top: 200px;
}

.noItems__text {
    margin-top: 200px;
    text-align: center;
}

@media (max-width: 500px) {
    .noItems__text {
        margin-top: 80px;
    }
}

.itemImage {
    object-fit: contain;
    height: fit-content;
}

.description {
    font-size: 12px;
    max-width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


.tabDescription__container {
    padding-top: 16px;
}

@media (max-width: 500px) {
    .tabDescription__container * {
        text-align: center;
    }
}

.subtle {
    color: var(--color-text-subtle);
}