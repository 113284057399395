.container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: fit-content;
    margin: auto;
}

.toggle {
    position : relative ;
    display : inline-block;
    width : 52px;
    height : 26px;
    background-color: #ADADAD;
    border-radius: 30px;
    border: 2px solid #ADADAD;
    cursor: pointer;
}

.toggle:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FFFFFF;
    top: .5px;
    left: .5px;
    transition:  all 0.5s;
}

.checkbox:checked + .toggle::after {
    left : 26px;
}

.checkbox:checked + .toggle.blue {
    background-color: #2C7DFE;
    border: 2px solid #2071f1;
}

.checkbox:checked + .toggle.green {
    background-color: #4EBA64;
    border: 2px solid #4EBA64;
}

.checkbox {
    display : none;
}

.icons {
    font-weight: bold;
    width: calc(100% - 6px);
    padding: 0 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.icon {
    height: 20px;
    width: 20px;
}

.icon * {
    color: #FFFFFF;
}

@keyframes toggleOpacity1 {
    0%, 100% {
        opacity: 1;
    }
    10%, 50% {
        opacity: 0;
    }
}
@keyframes toggleOpacity2 {
    0%, 100% {
        opacity: 1;
    }
    10%, 50% {
        opacity: 0;
    }
}
.animate1 {
    animation: toggleOpacity1 0.5s ease-in-out forwards; /* Apply animation on checked state */
}
.animate2 {
    animation: toggleOpacity2 0.5s ease-in-out forwards; /* Apply animation on unchecked state */
}