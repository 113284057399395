.container {
    margin-top: 20px;
}

@media only screen and (min-width: 500px) {
    .searchBar__container {
        width: 300px;
    }
}

.loadingSpinner__container {
    margin-top: 200px;
}

.noItems__text {
    margin-top: 200px;
    text-align: center;
}

@media (max-width: 500px) {
    .noItems__text {
        margin-top: 80px;
    }
}

.itemImage {
    object-fit: contain;
    height: fit-content;
}

@media (max-width: 500px) {
   .filters__container {
       flex-direction: column;
       gap: 10px;
       max-width: 100%;
   }
    .searchBar__container {
        max-width: 100%;
    }
}
