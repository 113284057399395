.textFieldContainer {
    position: relative;
    width: 100%;
}

.label {
    position: absolute;
    top: 8px;
    left: 8px;
    transition: all 0.2s ease;
    pointer-events: none;
    color: var(--color-text-subtle);
    font-size: 12px;
}

.inputContainer {
    position: relative; /* Required for absolute positioning of startIcon */
}

.startIcon {
    position: absolute;
    left: 12px; /* Adjust based on your padding */
    top: 50%; /* Center the icon vertically */
    transform: translateY(-50%); /* Align with the input text */
    pointer-events: none; /* Prevent icon click events */
    height: 100%; /* Ensure it matches input height */
    display: flex; /* Center align the icon vertically */
    align-items: center; /* Center the icon within its container */
}

.textField {
    color: var(--color-text-default);
    font-size: 16px;
    width: 100%;
    padding: 22px 8px 10px; /* Extra top padding to make space for the label */
    border: 1px solid var(--color-border-default);
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.2s ease;
    min-height: 54px; /* Minimum height for the textarea */
    overflow: hidden; /* Hide overflow to ensure it expands */
    white-space: pre-wrap; /* Allow wrapping of text */
    word-wrap: break-word; /* Break words if necessary */
}



.textField:focus {
    border-color: var(--color-border-active); /* Change this to your preferred focus color */
}

/* ACTIVE STYLING */
.textField:focus + .label,
.textField:not(:placeholder-shown) + .label {
    top: -8px; /* Move label above the input when focused or filled */
    left: 16px; /* Keep it aligned */
    font-size: 12px; /* Smaller font size when above */
    border: 1px solid var(--color-border-active);
}

.inputWithStart {
    padding-left: 30px; /* Adjust padding if start adornment exists */
}

.inputWithEnd {
    padding-right: 40px; /* Adjust padding if end adornment exists */
}

.beforeValueAdornment, .endAdornment {
    position: absolute;
    pointer-events: none; /* Prevent adornments from interfering with input events */
}

.beforeValueAdornment {
    top: 22px;
    left: 8px;
}

.endAdornment {
    transform: translateY(-50%); /* Center the adornments */
    top: 50%; /* Center the adornments vertically */
    right: 12px; /* Adjust based on your padding */
}

.textField__disabled {
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-border-default);
    cursor: default;
}

.textField__noLabel {
    padding-top: 0; /* Adjust if necessary to center vertically */
    padding-bottom: 0; /* Adjust if necessary to center vertically */
    line-height: 54px; /* Keep line-height consistent */
}

.textFieldContainer {
    position: relative; /* Keep this if you have adornments */
}

.textField {
    resize: vertical; /* Allows only vertical resizing */
    
}