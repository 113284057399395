.overlay {
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    --left-color: #2D7CFE;
    --right-color: #F3F7FB;
}

.container {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.box {
    width: 33px;
    height: 33px;
    position: relative;
    display: block;
    transform-origin: -50% center;
    border-radius: 15%;
}

.box:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: lightblue;
    border-radius: 15%;
    box-shadow: 0 0 5px 0 rgba(45, 124, 254, 0.2); 
}

@keyframes slide {
    0% {
        transform: translatex(0vw);
    }
    100% {
        transform: translatex(calc(250px - 41.25px)); /* Calculated value */
    }
}

@keyframes color-change
{
    0% { background-color: var(--left-color); }
    100% { background-color: var(--right-color); }
}

.box:nth-child(1) {
    animation: slide 1.5s ease-in-out infinite alternate;
}

.box:nth-child(1):after {
    animation: color-change 1.5s ease-in-out infinite alternate;
}

.box:nth-child(2) {
    animation: flip-1 1.5s ease-in-out infinite alternate;
}

.box:nth-child(2):after {
    background-color: var(--left-color);
    animation: squidge-1 1.5s ease-in-out infinite alternate;
}

.box:nth-child(3) {
    animation: flip-2 1.5s ease-in-out infinite alternate;
}

.box:nth-child(3):after {
    background-color: var(--left-color);
    animation: squidge-2 1.5s ease-in-out infinite alternate;
}

.box:nth-child(4) {
    animation: flip-3 1.5s ease-in-out infinite alternate;
}

.box:nth-child(4):after {
    background-color: var(--right-color);
    animation: squidge-3 1.5s ease-in-out infinite alternate;
}

.box:nth-child(5) {
    animation: flip-4 1.5s ease-in-out infinite alternate;
}

.box:nth-child(5):after {
    background-color: var(--right-color);
    animation: squidge-4 1.5s ease-in-out infinite alternate;
}

@keyframes flip-1 {
    0%, 15% { transform: rotate(0); }
    35%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-1 {
    5% { transform-origin: center bottom; transform: scaleX(1) scaleY(1);}
    15% { transform-origin: center bottom; transform: scaleX(1.3) scaleY(0.7);}
    25%, 20% { transform-origin: center bottom; transform: scaleX(0.8) scaleY(1.4);}
    55%, 100% { transform-origin: center top; transform: scaleX(1) scaleY(1);}
    40% { transform-origin: center top; transform: scaleX(1.3) scaleY(0.7);}
}

@keyframes flip-2 {
    0%, 30% { transform: rotate(0); }
    50%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-2 {
    15% { transform-origin: center bottom; transform: scaleX(1) scaleY(1);}
    30% { transform-origin: center bottom; transform: scaleX(1.3) scaleY(0.7);}
    40%, 35% { transform-origin: center bottom; transform: scaleX(0.8) scaleY(1.4);}
    70%, 100% { transform-origin: center top; transform: scaleX(1) scaleY(1);}
    55% { transform-origin: center top; transform: scaleX(1.3) scaleY(0.7);}
}

@keyframes flip-3 {
    0%, 45% { transform: rotate(0); }
    65%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-3 {
    25% { transform-origin: center bottom; transform: scaleX(1) scaleY(1);}
    45% { transform-origin: center bottom; transform: scaleX(1.3) scaleY(0.7);}
    55%, 50% { transform-origin: center bottom; transform: scaleX(0.8) scaleY(1.4);}
    85%, 100% { transform-origin: center top; transform: scaleX(1) scaleY(1);}
    70% { transform-origin: center top; transform: scaleX(1.3) scaleY(0.7);}
}

@keyframes flip-4 {
    0%, 60% { transform: rotate(0); }
    80%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-4 {
    35% { transform-origin: center bottom; transform: scaleX(1) scaleY(1);}
    60% { transform-origin: center bottom; transform: scaleX(1.3) scaleY(0.7);}
    70%, 65% { transform-origin: center bottom; transform: scaleX(0.8) scaleY(1.4);}
    100% { transform-origin: center top; transform: scaleX(1) scaleY(1);}
    85% { transform-origin: center top; transform: scaleX(1.3) scaleY(0.7);}
}

@keyframes flip-5 {
    0%, 75% { transform: rotate(0); }
    95%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-5 {
    45% { transform-origin: center bottom; transform: scaleX(1) scaleY(1);}
    75% { transform-origin: center bottom; transform: scaleX(1.3) scaleY(0.7);}
    85%, 80% { transform-origin: center bottom; transform: scaleX(0.8) scaleY(1.4);}
    100% { transform-origin: center top; transform: scaleX(1) scaleY(1);}
    95% { transform-origin: center top; transform: scaleX(1.3) scaleY(0.7);}
}