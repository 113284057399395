.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.tooltip {
    color: #636363;
    font-size: 14px;
    margin: 4px 0;
    font-style: italic;
}

/*.submitButton {*/
/*    height: 55px;*/
/*}*/