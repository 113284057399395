.listItemButton {
    border-radius: 10px;
}

.newBoxButton {
    margin-bottom: 50px;
    padding: 10px 5px;
}


/* Modal form styles */
.textfieldContainer {
    width: 80%;
    margin: 80px auto;
}

.subtitle {
    font-weight: lighter;
    font-size: 13px;
}

.submitButton {
    margin-top: 30px;
    background: #1C4EB4;
    width: 30%;
}