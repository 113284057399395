.snackbars__container {
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    top: 70px;
    margin: auto;
    width: max-content;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* individual snackbar */
.individualSnackbar__container {
    display: block;
    margin: auto;
}

.snackbar {
    width: max-content;
    max-width: min(400px, 90vw);
    height: fit-content;
    max-height: 300px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 3px;
    z-index: 20;
    position: relative; /* Ensure it's relative for the progress indicator to be absolute */
}

/* Progress indicator */
.progress__indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.2);
    animation-name: shrink;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes shrink {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}
/* color variants */
.regularColor {
    background: #b6c7e6;
} .regularColor * {
    color: #49495e
}

.successColor {
    background: var(--color-primary);
} .successColor * {
      color: White
  }

.errorColor {
    background: #ff7b7b;
} .errorColor * {
      color: #750808
  }


.warningColor {
    background: #fffe67;
} .warningColor * {
      color: #61610e
  }
