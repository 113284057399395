.modal {
    min-width: 1000px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.text__containers * {
    text-align: center;
}

.titles {
    font-weight: bold;
    font-size: 30px;
}

.guidingCards__container {
    display: flex;
    justify-content: space-evenly;
}

.card__icon {
    width: 40px;
    height: 40px;
}

.card__icon * {
    color: #FFFFFF !important;
}

.lostItemPageCard__description, .lostItemPageCard__description > * {
    color: #F5F5F5;
    margin: 0;
}

.copy__button {
    border-radius: 5px;
    border: none;
    background-color: #F5F5F5;
    color: #5F6368;
    transition: background-color 300ms ease, color 300ms ease;
}

.copy__button:hover {
    cursor: pointer;
}

.copy__button:active {
    background-color: #5d5d5d;
    color: White;
}

.needHelp__text {
    font-size: 20px;
    font-weight: lighter;
    color: #1C2739;
}

@media (max-width: 1000px) {
    .modal {
        min-width: 90%;
    }
    .guidingCards__container {
        display: block;
        margin: auto;
    }
}