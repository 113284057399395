.circleImage {
    border-radius: 90px;
    background: darkblue;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoverEffect:hover {
    cursor: pointer;
}