.pageContainer {
    background: #FFFFFF;
    height: 100dvh;
}

.contentCotnaienr {
    /*margin-top: 25px;*/
    width: 100%;
    display: flex;
    height: calc(100dvh - 65px);
}

.header__container {
    height: 65px;
}


.sidebarContainer {
    width: 350px;
    height: 100%;
    border-right: 0.5px solid var(--color-border-default);
}

.tab__container {
    width: 100%;
    height: 100%;
}

.tab {
    flex: 1;
    border-radius: 8px 8px 0 0;
    padding: 15px;
    width: calc(100% - 30px);
    height: calc(100%  - 30px); /* - padding */
    overflow-y: auto;
}

@media (max-width: 500px) {
    .tab {
        overflow-y: auto;
        overflow-x: auto;
    }
    .sidebarContainer {
        height: 100dvh;
    }
}

