/* Main container styles */
.responsiveGrid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    gap: 16px; /* gap between columns and rows */
}

/* Media query for smaller screens (like mobile devices) */
@media (max-width: 500px) {
    .responsiveGrid {
        grid-template-columns: 1fr; /* 1 column layout on small screens */
    }
}
