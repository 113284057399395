.tabContainer {
  display: flex;
  gap: 20px;
  height: 100%;
}

.editContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px
}

.emailType__title {
  font-size: 25px;
}

.emailType__description {
  color: #757575;
}

.switchContainer {
  display: flex;
  width: fit-content;
  gap: 15px;
  align-items: center;
}

.emailContent__edit {
  width: calc(100% - 16px);
  background: #e1e1e1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 4px 4px 0 0;
  align-items: center;
  gap: 5px;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}

.emailContent__edit:hover {
  background: #d3d3d3;
}

.emailContent__preview {
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 0 0 4px 4px;
  padding: 10px;
  width: calc(100% - 20px);
  height: 350px;
  cursor: pointer;
  overflow: scroll;
}

.actions__container {
  display: flex;
  gap: 10px;
}

.modal {
  min-width: 95vw;
}