.container {
    margin-top: 20px;
}

.loadingSpinner__container {
    margin-top: 200px;
}

.noItems__text {
    margin-top: 200px;
    text-align: center;
}

@media (max-width: 500px) {
    .noItems__text {
        margin-top: 80px;
    }
}

.itemImage {
    object-fit: contain;
    height: fit-content;
}

@media (max-width: 500px) {
   .filters__container {
       flex-direction: column;
       gap: 10px;
       max-width: 100%;
   }
    .searchBar__container {
        max-width: 100%;
    }
}

.tabDescription__container {
    padding-top: 16px;
}

@media (max-width: 500px) {
    .tabDescription__container * {
        text-align: center;
    }
}

