.container {
    position: fixed;
    right: 10px;
    bottom: 10px;
    margin: auto;
    width: max-content;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.completeIcon * {
    color: var(--color-primary) !important;
    font-size: 40px !important;

}

.cardsContainer {
    position: relative;
    width: 150px; /* Adjust as necessary */
    height: 150px; /* Adjust as necessary */
}

.card {
    position: absolute;
    width: 90px;
    height: 90px;
    background: white;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.card:nth-child(1) {
    z-index: 1; /* Ensures newer cards appear in front of older ones */
}

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure spinner is in front of all cards */
}
