.batch__container {
    border-radius: 4px;
    background: var(--color-warning);
    color: #FFFFFF;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 2px 4px;
}

.batch__container * {
    color: #FFFFFF !important;
    font-size: 14px !important;
}