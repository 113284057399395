.container {
    max-width: 100%;
}

.tab__header {
    display: flex;
    gap: 24px;
    margin: 32px 0;
}

.header {
    display: flex;
    gap: 16px;
    align-items: center;
}

.searchBar__container {
    width: 300px;
}

.addUser__button {
    height: 56px;
}

@media only screen and (max-width: 500px) {
    .header {
        flex-direction: column;
        gap: 8px;
    }
    .searchBar__container, .addUser__button {
        width: 100%;
    }
}