.filters__container {
    margin: 16px 0 8px 0;
    display: flex;
    gap: 8px;
    max-width: 500px
}

.searchBar__container {
    flex: 1;
}

.otherFilters__container {
    width: 180px;
}

.mobileFilter__button {
    width: 56px;
    height: 56px;
    min-width: 56px;
}

.drawer {
    display: flex;    
    flex-direction: column;
    gap: 12px;
    padding: 8px;
}


@media (max-width: 500px) {
    .searchBar__container {
        width: 100%;
    }
    .otherFilters__container {
        width: 56px;
    }
}
