.header {
    height: calc(65px - 24px); 
    padding: 12px 20px;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    justify-content: space-between;
    align-items: center
}


.leftContainer {
    width: 33%;
    display: flex;
}


.contentRight {
    width: 33%;
    display: flex;
    justify-content: right;
    gap: 21px;
    align-items: center;
}

.pageTitle__container {
    display: flex;
    text-align: left;
    gap: 5px;
    align-items: center;
}

.progressBars {
    width: 100vw;
    position: absolute;
}


