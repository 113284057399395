.matchOptions__container {
    border-radius: 8px;
    border: 1px solid #F0F3F9;
    height: 100%;
    padding: 15px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.container {
    padding: 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.container:hover {
    background: #F3F7FE;
}

.container__selected {
    background: #F3F7FE;
    border-bottom: 3px solid #2C7DFE;
    cursor: default;
}

.image {
    object-fit: cover;
    border-radius: 6px;
}

.right__container {
    flex: 1;
}

.category {
    font-size: 15px;
    color: #999999
}

.description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Maximum number of lines */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
}

.probability, .probability *  {
    margin-top: 2px;
    font-size: 13px !important;
    color: #50b542 !important;
    gap: 3px;
    display: flex;
    align-items: center;
}