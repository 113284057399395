.inputField > * {
    width: 80%;
    margin: auto;
}

/* Modal form styles */
.textfieldContainer {
    width: 80%;
    margin: 10px auto;
}

.subtitle {
    font-weight: lighter;
    font-size: 13px;
}

.submitButton {
    margin-top: 30px;
    background: #1C4EB4;
    width: 30%;
}