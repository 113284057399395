.previewColor {
    width: 100%;   
    height: 50px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.previewColor:hover {
    border: 1px solid #242424;
}