.container {
    min-height: 320px;
    background: #1762da;
    width: 300px;
    border-radius: 5px;
}

.content {
    margin: 23px 21px;
}

.tag {
    background: #F5F5F5;
    color: #5F6368;
    font-weight: lighter;
    padding: 5px 14px;
    border-radius: 5px;
    box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.06);
    width: fit-content;
    margin: 0;
}

.titleIcon__container {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: White;
}

.icon__container {
    transform: rotate(15deg);
    margin: 0 2px 10px 4px;
    width: 40px;
    height: 40px;
}

.overLine__container {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.white__line {
    background: #FFFFFF;
    width: 100%;
    height: 1px;
}

.description__container {
    margin: 50px 0 20px;
    height: 100px;
}