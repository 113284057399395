.multiSelect__checkbox * {
    color: #2D7CFE !important;   
}

.skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
}
.skeleton *  {
    color: var(--color-text-subtle) !important; /* make all childrens text subtle */
}

.skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.headerCell, .MuiTableCell-root.headerCell {
    padding: 8px !important; /* Adjust as needed */
    border-bottom: 1px solid var(--color-gray-100) !important;
}

.tableCell, .MuiTableCell-root.tableCell  {
    padding: 8px !important; /* Adjust as needed */
    border-bottom: 1px solid var(--color-gray-100) !important;
    
}
