.container *:not([class*="circleImage"]) { /* ensure the circle image text dont get affected */
    font-size: 15px !important;
}

.userInfo__container {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 4px 10px 10px 10px;
}
.username {
    font-weight: bold;
    font-size: 16px !important;
}

.email {
    font-size: 14px !important;
}

.organisations__title {
    color: #A1A1A1;
    font-weight: bold;
    margin: 10px 0 8px 10px;
}

.menuItem:hover {
    background: #eaeaea !important;
}

.selectedItem, .selectedItem:hover {
    background: #d2d2da !important;
    border: #c8c8d1 1px solid !important;
}

.organisationsLogo {
    background: #315998 !important;
}
