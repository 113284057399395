.tabContainer {
    display: flex;
    width: fit-content;
    flex-wrap: wrap; /* Allow tabs to wrap onto multiple rows */
    background-color: var(--color-background-subtle);
    border-radius: 8px;
    padding: 6px;
    gap: 4px; /* Space between tabs */
    position: relative; /* Needed for positioning */
}

.tab {
    position: relative;
    padding: 8px 16px;
    font-size: 16px;
    color: #555;
    border-radius: 6px;
    cursor: pointer;
   
    transition: background-color 0.2s ease, transform 0.2s ease; /* Smooth transitions */
}

.content {
    display: flex;
    gap: 4px;
    align-items: center;
}

.tab:hover {
    background-color: #e6e6e6; /* Change background on hover */
}

.selected, .selected:hover {
    background-color: #fff; /* Selected tab background */
    font-weight: bold;
}