

/* Modal styling */
.container {
    display: flex;
    flex-direction: column;
}

.container > * {
    margin: 8px auto !important;
}

.split__container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.split__container > * {
    width: 50%;
}

.switch > * {
    background: #2C7DFE;
}

.moreFields__button {
    padding: 10px 0 0;
    font-size: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.switches__section {
    display: flex;
    gap: 10px;
}

.switches__labelContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.switches__labelContainer > * {
    display: flex;
    gap: 3px;
    justify-content: right;
}

.switches__container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}