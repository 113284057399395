.background {
    background: rgba(1,1,1,0.5);
    position: fixed;
    overflow: hidden;
    z-index: 10;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    pointer-events: auto;
}

.modal {
    position: absolute;
    /*background: #F3F7FB;*/
    background: #FFFFFF;
    width: 75vw;
    max-width: 500px;
    top: 0;
    height: 100dvh;
}

.contentContainer {
    margin: 16px 0 0 0;
    height: calc(100% - 16px);
}