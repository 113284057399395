.tableActions__container {
    display: flex;
    justify-content: right;
    gap: 10px;
    width: fit-content;
    float: right;
}

.noFoundItems__text {
    margin: 10px 0 20px;
}

.noFoundItems__text, .noFoundItems__text * {
    font-size: 15px !important;
    color: #3E3E3E !important;
    display: flex;
    gap: 5px;
    align-items: center;
}