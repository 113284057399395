.button {
    background: #2C7DFE;
    color: #FFFFFF;
    text-transform: none;
    border-radius: 8px;
    border: none;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    font-family: Helvetica, sans-serif;
}

.button * {
    color: #FFFFFF;
}

.button:hover {
    background: #1972ff;
    cursor: pointer;
}

.secondary {
    background: #ffffff;
    color: #1972ff;
    cursor: pointer;
    border: 1px solid #1972ff;
}
.secondary * {
    color: #1972ff;
}
.secondary:hover {
    background: #f1f1f1;
    color: #1972ff;
    cursor: pointer;
}

.quiet {
    background: #ffffff;
    color: #1972ff;
    cursor: pointer;
    font-weight: bold;
}
.quiet * {
    color: #1972ff;
}
.quiet:hover {
    background: #F0F0F2;
}

.warn {
    background: #e60000;
    color: #ffffff;
    cursor: pointer;
}
.warn * {
    color: #ffffff;
}

.warn:hover {
    background: #ca0000;
    color: #ffffff;
    cursor: pointer;
}

.white {
    border: 1px solid var(--color-border-default);
    background: var(--color-background-default);
    color: var(--color-text-default);
    cursor: pointer;
}
.white * {
    color: var(--color-text-default);
}

.white:hover {
    background: var(--color-background-hover);
    color: var(--color-text-default);
    cursor: pointer;
}

.button:disabled {
    background: #f8f8f8;
    color: var(--color-border-default);
    cursor: not-allowed;
    border: 1px solid var(--color-border-default);
}

.button:disabled * {
    color: var(--color-border-default);
}

.size__small {
    padding: 8px 10px;
    gap: 8px
}

.size__normal {
    padding: 12px 25px;
    min-width: 170px;
}

.size__large {
    padding: 12px 25px;
    min-width: 170px;
}