.container {
    padding-top: 15px;
    /*margin-right:  30px;*/
    height: calc(100% - 25px);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-width: 500px) {.container {
    padding-top: 0;
}}

.postButton__container {
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.foundItem__button {
    width: calc(100% - 30px);
    justify-content: left;
}

.newFoundItemButton {
    justify-content: left;
}
.secondayFoundItem__button {
    max-height: 40px;
}

.tabs__list {
    /*margin-top: 50px;*/
    padding-left: 0;
}

.tabs__listItem {
    list-style: none;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px 0 10px 30px;
    border-radius: 0 8px 8px 0;
    gap: 20px;
    cursor: pointer;
}
.tabs__listItem * {
    color: #656B76
}

.tabs__listItem:hover, .selected.tabs__listItem:hover {
    background: var(--color-gray-50);
}

.selected.tabs__listItem {
    /*background: #FFFFFF;*/
    border-left: 2px solid var(--color-primary);
}
.selected.tabs__listItem * {
    color: #2C7DFE;
}

.divider {
    color: #DBDBDB
}

.organisationInfo__container {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.organisationInfo__container * {
    color: #656B76
}

/* OLD*/
.boxes__list:hover {
    overflow-y: auto;
}

.boxes__list {
    flex: 1 !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.listItemButton {
    border-radius: 10px !important;
}

.selectedTab {
    background: #FFFFFF !important;
}

.selectedTab__icon * {
    color: #2C7DFE !important;
}

.signOutButtonContainer {
    margin-top: 80px;
    margin-bottom: 10px;
}