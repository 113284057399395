.iconButton:hover {
    cursor: pointer;
}

/* Modal form styles */
.subtitle {
    font-weight: lighter;
    font-size: 13px;
}

.submitButton {
    margin-top: 30px;
    background: #1C4EB4;
    width: 30%;
}

.emailChip {
    background: #e1e1e1 !important;
}