.background {
    background: rgba(1,1,1,0.5);
    position: fixed;
    overflow: hidden;
    z-index: 10;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    pointer-events: auto;
    max-height: 100dvh;
}

.modal {
    position: absolute;
    background: #FFFFFF;
    height: fit-content;
    width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    max-width: 650px;
    overflow: hidden;
    max-height: 100dvh;
}

@media (max-width: 500px) {
    .modal {
        width: 100vw !important;
        /*border-radius: 0;*/
    }
}

.modalHeader {
    width: calc(100% - 24px - 24px);
    margin: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalHeader__side {
    gap: 24px;
    display: flex;
    align-items: center;
}

.iconButton {
    font-size: 24px;
    font-weight: bold !important;
    cursor: pointer;
}

.contentContainer {
    padding: 24px 16px;
    max-height: calc(100dvh - 29px - 16px - 16px);
    overflow-y: auto;
}

/* Sliding animation */

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.slideOut {
    animation: slideOutToLeft 0.3s ease-out forwards;
}

.slideIn {
    animation: slideInFromRight 0.3s ease-out forwards;
}

