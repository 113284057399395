.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.container {
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    width: 450px;
    max-width: calc(100% - 40px);
    padding: 10px 20px;
}

.title {
    margin-top: 10px;
}



