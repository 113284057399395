.tabContainer {
    display: flex;
    gap: 20px;
    height: 100%;
}

.matchOptions__container {
    height: 100%;
    padding: 15px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.compareMatch__container {
    height: 100%;
    padding: 15px;
    flex: 1;
}

.buttons__container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.button {
    background-color: #FFFFFF;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 100ms ease-in-out;
}
.button__decline:hover {
    background-color: #fff5f5;
}
.button__approve:hover {
    background-color: #f7f9ff;
}
.button * {
    font-size: 30px;
}

.button__decline {
    border: 1px solid #ff0000;
}
.button__decline * {
    color: #ff0000;
}
.button__approve {
    border: 1px solid #2C7DFE;
}
.button__approve * {
    color: #2C7DFE;
}

