.form {
    width: 100%;
    padding-top: 5px;
}

.form > * {
    display: block;
    margin: auto;
}

.submitButton {
    margin-top: 30px;
}