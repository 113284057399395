.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
    
}

.menuItem:hover {
    background: #eaeaea !important;
}

.selectedItem, .selectedItem:hover {
    background: #d2d2da !important;
    border: #c8c8d1 1px solid !important;
}
