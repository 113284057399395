.body {
    margin: 0;
    color: var(--color-text-default);
    font-size: 16px;
    font-weight: normal;
}

.body-strong {
    margin: 0;
    color: var(--color-text-default);
    font-size: 16px;
    font-weight: bold;
}


.caption {
    margin: 0;
    color: var(--color-text-default);
    font-size: 14px;
    font-weight: normal;
}

.detail {
    margin: 0;
    color: var(--color-text-default);
    font-size: 12px;
    font-weight: normal;
}
