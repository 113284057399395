.textField fieldset {
    border-color: var(--color-border-default) !important;
    border-radius: 8px !important;
}

.textField * {
    color: var(--color-text-subtle) !important;
}

.options__container {
    background: #FFFFFF !important;
}

.option {
    display: block;
    background: #FFFFFF;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 8px 8px;
    font-size: 15px;
    white-space: nowrap;        /* Prevent text from wrapping */
    overflow: hidden;           /* Hide overflowed text */
    text-overflow: ellipsis;    /* Show ellipsis for overflowed text */
}

.option:hover, .option:active {
    background: #e8e8e8;
    outline: none;
}

.chip {
    background: var(--color-background-default) !important;
    border: 1px solid var(--color-border-default) !important;
}