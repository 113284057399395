.container {
    background: #F7FAFD;
    height: 100vh;
}

.logo {
    position: absolute;
    margin: 20px 17px;
    object-fit: contain;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 60px 40px;
    width: 500px;
}

.form > * {
    margin: 5px auto !important;
}

.title {
    text-align: center;
}

.errorMessage {
    color: red;
    text-align: center;
}

.noUser__text {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.noUser__link {
    text-decoration: underline;
    cursor: pointer;
}

.resetPasswordLink {
    color: #acacac;
    display: block;
    text-align: right;
    text-decoration: underline;
    margin: 0 0 15px;
    font-size: 14px;
}

@media (max-width: 500px) {
    .form {
        max-width:  90%;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }
}