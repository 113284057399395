.container {
    object-fit: contain;
    height: fit-content;
    margin: auto;
    display: block;
}

.thumbnail {
    width: 300px;
    height: 300px;
    cursor: pointer;
    object-fit: contain;
}

.fullScreenContainer {
    background: rgb(1,1,1);
    position: fixed;
    overflow: hidden;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
}

.fullImage {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.closeButton {
    position: fixed;
    top: 20px;
    left: calc(100vw - 60px);
    z-index: 21;
    color: #FFFFFF !important;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

.closeButton {
    color: #FFFFFF !important;
}