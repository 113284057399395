.contentContainer {
    display: flex;
    justify-content: center; 
    align-items: center;   
    height: calc(100vh - 100px);
    padding: 0 10px;
}

.header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    object-fit: contain;
}

.userIcon {
    
}

.textContainer {
    margin-bottom: 45px;
    text-align: center;
}

.formContaienr {
    margin: auto;
}

.organisationSection {
    margin-top: 60px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    background: #e3e3e3;
    padding: 0 20px 20px 20px;
    border-radius: 7px;
}
.organisationSection__title {
    font-weight: bold;
    color: #595959;
}

.organisation {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
}

.organisation:hover {
    background: #c1c1c1;
    cursor: pointer;
}

.awaitInvite__flexContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.awaitInvite__divider {
    flex: 1 !important;
}

.awaitInvite__text {
   text-align: center;
    color: #595959;
}