.editForm__container {
    display: flex;
    gap: 40px;
}
.inputs__container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.preview__container {
    padding: 10px;
}
@media (max-width: 500px) {
    .editForm__container {
        display: block;
    }
}

.previewMode__button {
    background: #f4f4f4;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.previewMode__container {
    display: flex;
    align-items: center;
    gap: 10px
}

.submit__button {
    margin-top: 10px;
    margin-bottom: 40px;
}

.howToUseForm__buttons {
    display: flex;
    gap: 15px;
    flex: 1;
}

.howToUseForm__buttons > * {
    flex: 1;
}

.howToUseForm__container {
    max-width: 600px;
    display: block;
    margin: 100px auto 10px auto;
}

.howToUseForm__container > * {
    display: flex;
    justify-content: center;
}