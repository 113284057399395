.container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    width: max-content;
    /*background: var(--color-background-subtle);*/
    /*border-radius: 4px;*/
    /*border: 1px solid #eaeaea;*/
}

.container * {
    color: var(--color-text-subtle) !important;
}

.truncated .categoryName {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
