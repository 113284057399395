.container {
    padding: 15px;
}

.flexContainer {
    display: flex;
    justify-content: space-evenly;
}

.flexContainer > * {
    width: 45%;
}


.probability, .probability *  {
    font-size: 15px !important;
    color: #50b542 !important;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
    text-align: center;
}

.id {
    margin-top: 10px;
    font-size: 12px;
    color: #808080;
    text-align: center;
}

.image__container {
    height: 300px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    
.image {
    display: flex;
    justify-content: center;
    object-fit: contain;
    height: fit-content;
    width: fit-content;
    max-width: 300px;
    max-height: 300px;
    border-radius: 6px;
}

.image__none {
    background: #CECECE;
    width: 250px;
    height: 250px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
}

.category {
    font-size: 16px;
    color: #242424;
    text-align: center;
}

.time {
    margin-top: 10px;
    font-size: 14px;
    color: #242424;
    text-align: center;
}

.sectionSplit {
    margin: 55px 0;
    text-align: center;
}

.sectionSplit * {
    color: #aeaeae !important;
    font-size: 15px;
}

.description {
    width: 300px;
    text-align: center;
    margin: auto;
}